<template>
  <div :class="`m2 wrapper wrapper--${bu}`">
      <h3 class="_text-center mt-2">Leverancier top 100</h3>
      <hr>

    <Loading v-if="loading" />
    <Tabs v-else>
      <Tab v-for="(buyer, key) in supplier_top_100" :key="key" :title="key">
        <div class="p2">
          <SupplierTop100Table :table_data="buyer" :bu="bu" />
        </div>
      </Tab>
    </Tabs>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue";
import Tabs from "@/components/Tabs/Tabs.vue";
import Tab from "@/components/Tabs/Tab.vue";
import SupplierTop100Table from "@/components/SupplierTop100Table.vue";

export default {
  props: ["bu"],
  components: { Loading, Tab, Tabs, SupplierTop100Table },
  data: () => ({
    loading: true,
    supplier_top_100: null,
  }),
  created() {
    this.getData(this.bu);
  },
  watch: {
    bu(newbu) {
      this.supplier_top_100 = null;
      this.loading = true;
      this.getData(newbu);
    },
  },
  methods: {
    getData(bu) {
      request(`supplier-top-100/${bu}`, "GET").then(({ supplier_top_100 }) => {
        this.supplier_top_100 = supplier_top_100;
        this.loading = false;
      });
    },
  },
};
</script>
